import { connect } from '../../common/components/runtime-context';
import { createRtfFormatter } from '@wix/yoshi-flow-editor';
import { getLanguage } from '../../common/store/basic-params/basic-params-selectors';

let formatRelativeTime;

export default function withRelativeTimeFormatting(WrappedComponent) {
  const mapRuntimeToProps = state => {
    const language = getLanguage(state);
    if (!formatRelativeTime) {
      formatRelativeTime = createRtfFormatter(
        language,
        { floorSeconds: true, extraShortExpression: true },
        { style: 'narrow' },
      );
    }
    return {
      formatRelativeTime,
    };
  };

  return connect(mapRuntimeToProps)(WrappedComponent);
}
